var _sentryCollisionFreeGlobalObject = typeof window === "undefined" ? global : window;
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"09312845ed6ca6e1ab1454cef6866ad440a9ad74"};
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

import * as Sentry from '@sentry/nextjs'

const dsn = process.env.NEXT_PUBLIC_SENTRY_DSN
const environment = process.env.NEXT_PUBLIC_APP_ENV

if (environment !== 'local') {
    Sentry.init({
        dsn,
        environment,
        tracesSampleRate: 0.1, // 10% of all transactions will be captured
    })
}
